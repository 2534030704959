<template>
  <component :is="loginComponent"></component>
</template>

<script>
import { getParams } from "@/api/session.js";
import DomainStyle from "@/imported/DomainStyle.json";

export default {
  name: "SessionCreate",
  data() {
    return {
      loginStyle: DomainStyle.default,
    };
  },
  async beforeRouteEnter(to, from, next) {
    const response = await getParams({ domain: window.location.hostname }),
      { domain } = response.data;
    return next(vm => {
      vm.loginStyle = domain.styleId;
    });
  },
  computed: {
    loginComponent() {
      switch (this.loginStyle) {
        case DomainStyle.LaCentral:
          return () => import("./logins/LoginStyleLaCentral.vue");
        default:
          return () => import("./logins/LoginStyleDefault.vue");
      }
    },
  },
};
</script>

<style scoped>
#container {
  background-image: url("../../../public/img/backgrounds/login-background.jpg");
  background-size: 100% 100%;
}

.container.fill-height > .row {
  max-width: none;
}
</style>
